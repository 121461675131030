@import "../../assets/styles/main.scss";

.modal{
  padding-inline: 32px !important;
}
.modalBody {
  .text {
    font-size: 14px;
    color: #3e5e6e;
    margin: 10px 0px 8px;
    line-height: 1.71;
  }
  .imageStyle {
    border: 2px solid #979797;
    margin-left: 32px;
  }
  .imageBorderNone {
    border: none !important;
  }
  .imageStyle:nth-of-type(1) {
    margin-left: 0px;
  }
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}
