@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  overflow: auto;
  padding: 32px;

  .backIconContainer {
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
    height: 17px;
    align-items: center;

    .backIconLabel {
      display: flex;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3196f6;
    }

    .backIcon {
      display: flex;
      margin-right: 8px;
    }
  }

  .Heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 30px;
    color: #3e5e6e;
  }

  .ParagraphText {
    font-size: 14px;
    line-height: 1.71;
    font-weight: $fw-normal;
    margin-bottom: 16px;
    color: #3e5e6e;
  }

  .Paragraph {
    font-size: 14px;
    line-height: 1.71;
    font-weight: $fw-normal;
    margin-bottom: 22px;
    color: #3e5e6e;
  }

  .stepTitle {
    font-size: 14px;
    line-height: 1.71;
    margin-top: 24px;
    margin-bottom: 24px;
    color: #3e5e6e;
    display: flex;
    flex-wrap: wrap;
    .stepBoldText {
      margin: 0 5px;
    }
  }

  .stepIcon {
    width: 24px;
    margin-left: 5px;
  }

  .imageContainer {
    width: 180px;
    background-color: #f8f8f8;

    .stepImg {
      width: 180px;
      object-fit: contain;
    }
  }
}
