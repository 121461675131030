@import "../../../assets/styles/main.scss";

.Nav {
  position: relative;
  padding-top: 4px;

  li {
    display: flex;
    align-items: center;

    .link {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 22px;
    }
    .disableLink {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #6d8694;
      margin-top: 22px;
      pointer-events: none;
    }
    .navIcon {
      position: relative;
      width: 20px;
      height: 20px;
      .tickIcon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: -12px;
        right: -10px;
        object-fit: contain;
      }

      .publish {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #e66565;
        top: -6px;
        right: -6px;
        border-radius: 50%;
      }
    }
  }
}

.Label {
  margin-left: 16px;
}

.Active {
  color: #3196f6;
}
.titleContainer{
  margin-top: 21px;
  width: 100%;
}
.Line {
  width: 100%;
  height: 2px;
  border-top: solid 1px #3e5e6e;
  margin: 8px 0 16px 0;

}
.AppSettingsText {
  font-size: 10px;
  font-weight: 500;
  color: #9fafb6;
}
