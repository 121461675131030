@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  margin-bottom: 16px;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.BlockInput {
  display: block;
  width: 100%;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 8px 16px;
  font-family: inherit;
  font-size: 14px;
  color: #3e5e6e;
  font-weight: $fw-medium;
  border: $border-input-default;

  &::placeholder {
    color: #9fafb6;
  }
}


.Invalid {
  border: $border-error;
}

.Valid {
  border: $border-input-default !important;
}