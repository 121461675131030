.alert {
  position: relative;
  width: 90%;
  padding: 20px;
  object-fit: contain;
  border-radius: 4px;
  background-color: rgba($color: #ff8360, $alpha: 0.18);
}

.alertHead {
  display: flex;
  align-items: center;
}

.alertTitleButton {
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #ff8360;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.alertIcon {
  margin-left: 5px;
  width: 21px;
}

.alertClose {
  background: transparent !important;
  border: none !important;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.alertBody {
  margin-top: 14px;
}

.alertDescription {
  color: #3e5e6e;
  font-size: 12px;
}

.pricingLink {
  font-weight: bold;
  color: #1f81df;
}
