@import "../../../../assets/styles/main.scss";

.EngageSubRouteContainer {
  height:100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  color: #3e5e6e;
}
.Info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  margin-top: 16px;
  color: #3e5e6e;
}

.LanguageContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  .LanguageItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    .BlockLabel {
      display: block;
      font-size: 14px;
      font-weight: $fw-bold;
      line-height: 150%;
      color: #3e5e6e;
      margin-bottom: 8px;
    }
    .errorLabel {
      color: red;
      font-size: 12px;
      font-weight: $fw-bold;
    }
  }

  .LanguageItem:first-child {
    margin-right: 16px;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
