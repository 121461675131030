.ProgressContainer {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #e7eaec;
  margin-bottom: 16px;
  position: relative;
}

.Progress {
  height: 100%;
}

.progressLoading {
  position: absolute;
  right: 6px;
  top: 3px;
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.Success {
  background-color: #3fc48a;
}

.Warning {
  background-color: #4da8ff;
}

.Danger {
  background-color: #e66565;
}




.loader {
  font-size: 10px;
  text-indent: -9999em;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #d1d1d1;
  background: -moz-linear-gradient(left, #d1d1d1 10%, rgba(209,209,209, 0) 42%);
  background: -webkit-linear-gradient(left, #d1d1d1 10%, rgba(209,209,209, 0) 42%);
  background: -o-linear-gradient(left, #d1d1d1 10%, rgba(209,209,209, 0) 42%);
  background: -ms-linear-gradient(left, #d1d1d1 10%, rgba(209,209,209, 0) 42%);
  background: linear-gradient(to right, #d1d1d1 10%, rgba(209,209,209, 0) 42%);
  position: absolute;
  right: 6px;
  top: 6px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #d1d1d1;
  border-radius: 100% 0 0 0;
  position: absolute;
  right: 6px;
  top: 6px;
  content: '';
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}