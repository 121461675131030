@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.BlockInput {
  display: block;
  width: 200px;
  height: 32px;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 28px 11px 16px;
  font-family: inherit;
  font-size: 14px;
  color: #3e5e6e;
  font-weight: $fw-medium;
  border: $border-input-default;

  &::placeholder {
    color: #9fafb6;
  }
}

.Select {
  display: block;
  width: 100%;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-size: $fs-small;
  font-weight: $fw-medium;
  font-weight: 500;
  border: none;
  color: #9fafb6;
  height: 40px;
}

.Invalid {
  border: $border-error;
}

.Valid {
  border: $border-input-default !important;
}

.SearchIcon{
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  padding: 4px;
  margin: 4px;
}
