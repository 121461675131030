.root {
  background-color: #3196f6;
  height: 2px;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: -3px;

  margin-bottom: 10px;
  background-color: #3196f6;
  border-radius: 4px;
}
