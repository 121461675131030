@import "../../assets/styles/main.scss";

.DesignContainer {
  width: 100%;
  height: 100%;
}

.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 16px;
  text-align: center;
  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }
}

.ModalFooter {
  .ButtonContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    > button:first-child {
      margin-right: 20px;
    }
  }
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
