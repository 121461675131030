@import "../../../assets/styles/main.scss";

.Container {

  .Pagination{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top:16px;

    .PrevPage{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin: 0 4px;
        border: solid 1px #cfd7db;
        background-color: #f3f5f6;
        a{
            display: flex;
        align-items: center;
        justify-content: center;
        }
      
    }
    .NextPage{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px #cfd7db;
        background-color: #f3f5f6;
        margin: 4px 0  4px 4px;
        a{
            display: flex;
        align-items: center;
        justify-content: center;
        }
      
    }
    .DisablePage{
        opacity: 0.8;
        color: currentColor;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
    }
    .CurrentPage{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 59px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px #cfd7db;
        background-color: white;
        margin: 0 4px;
        a{
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9fafb6;
        }
    }
}


}


