@import "../../../assets/styles/main.scss";

.NavigationContainer {
  padding: 16px;
  background-color: $text-color-light-1;
}

.InfoText {
  width: 186px;
  height: 32px;
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-color-dark-1;
}

.InfoGroup {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.Information {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 8px;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  color: #3e5e6e;
  line-height: 150%;
  margin-bottom: 8px;
}

.NavigationOption {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.DesignConfigContainer {
  height: 100%;
  width: 272px;
  float: left;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
}

.componentsContainer {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  height: 100%;
}

.MobileScreen {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 700px;
}

.EmptyNav {
  width: 72px;
  height: 72px;
  margin: 4px;
}

.Nav {
  margin: 8px 0px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.ActiveBorder {
  border: solid 1px #3e5e6e !important;
}
.NavigationBarLogoContainer {
  margin: 16px 0;
  padding: 16px;
  background-color: $text-color-light-1;
}
.InfoGroup {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
.Information {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 8px;
  margin-top: 3px;
}

.TheChangesYouMake {
  width: 186px;
  height: 32px;
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.Label {
  width: 43px;
  height: 16px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.Active {
  color: #3e5e6e;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin: 32px 32px;
  z-index: 11;
}