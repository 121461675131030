@import "../../assets/styles/main.scss";

@font-face {
  font-family: "Red Hat Display Black";
  src: url("../../assets/fonts/RedHatDisplay-Black.ttf") format("truetype");
}

.WelcomeContainer {
  width: 90%;
  margin: 88px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WelcomeHeading {
  color: #3196f6;
  font-size: $fs-title;
  margin-bottom: 8px;
  text-align: center;
  letter-spacing: 0.5px;
  font-family: "Red Hat Display Black";
}

.WelcomeParagraph {
  font-size: $fs-p;
  line-height: 150%;
  text-align: center;
  width: 67%;
  line-height: 1.44;
  margin: 0 auto 50px;
  color: #3e5e6e;
}

.BoxContainer {
  display: flex;
  justify-content: space-evenly;
}

.Box {
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 25px 16px;
  width: 24%;
  transition: all 200ms ease-in-out;
  text-align: center;
  margin-right: 16px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    color: #3e5e6e;
  }

  p {
    font-size: $fs-small;
    line-height: 150%;
    margin-top: 15px;
    color: #3e5e6e;
  }
}

.ButtonContainer {
  margin-top: 48px;
  display: flex;
  justify-content: center;

  > button:first-child {
    margin-right: 20px;
  }
}


.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 10px;
  margin-bottom: 20px;
  .ModalBodyText {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: start;
    color: #3e5e6e;
  }
}

.ModalFooter {
  .ButtonContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    > button:first-child {
      margin-right: 20px;
    }
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.ModalBody {
  margin-top: 16px;

  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: start;
    margin-bottom: 20px;
    color: #3e5e6e;
  }

  .ConfirmIcon {
    margin: 16px;
  }
}

.ModalFooter {
  .ButtonGroup {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    > button:first-child {
      margin-right: 20px;
    }
  }
}