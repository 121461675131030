@import "../../../assets/styles/main.scss";

.container {
  height: 100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
  position: relative;
  button[type="submit"] {
    float: right;
    margin-top: 20px;
    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }
}

.backIconContainer {
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  height: 17px;
  align-items: center;

  .backIconLabel {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3196f6;
  }

  .backIcon {
    display: flex;
    margin-right: 8px;
  }
}

.heading {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 8px;
  margin-top: 16px;
  color: #3e5e6e;
}

.subHeading {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 8px;
  margin-top: 16px;
  color: #3e5e6e;
}

.bodyArea {
  display: flex;
  margin: 20px 0 10px;
  justify-content: space-between;
  max-width: 800px;
}

.leftArea {
}

.rightArea {
  img {
    width: 100%;
    max-width: 460px;
    margin-top: 50px;
  }
}

.qr {
  text-align: center;
  margin: 30px 0;
}

.description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #3e5e6e;
  margin-bottom: 16px;
}

.Paragraph {
  font-size: 14px;
  line-height: 1.71;
  font-weight: $fw-normal;
  margin-bottom: 32px;
  color: #3e5e6e;
}
.CheckboxList {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .CheckboxGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    justify-content: space-between;
  }
}

.emailGroupArea {
  max-width: 769px;
}

.EmailGroup {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .EmailGroupTitle {
    font-size: 14px;
    font-weight: bold;
    color: #0f3649;
    margin-bottom: 10px;
  }

  .emailList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;

    .removeIcon {
      cursor: pointer;
      display: flex;
      margin-bottom: 8px;
      margin-left: 10px;
      height: 54px;
      display: flex;
      align-items: center;
    }
    .emailInput {
      width: 100%;
    }
    .inputMargin {
      margin-right: 24px;
    }
  }
  .newEmailWrapper {
    display: flex;
    justify-content: flex-end;

    .newEmailContainer {
      cursor: pointer;
      display: inline-flex;

      .plusIconLabel {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: #3196f6;
      }

      .plusIcon {
        display: flex;
        margin-right: 6px;
      }
    }
    .newEmailMargin {
      margin-right: 24px;
    }
  }
}
.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0px;
}

.contentContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.tutorialButton {
  cursor: pointer;
  margin-left: 12px;
  margin-top: 10px;
  svg {
    fill: #e66565;
  }
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.headInfo {
  margin-top: 24px;
  p {
    font-size: 14px;
    font-weight: normal;
    color: $theme-dark-1;
  }
}

.qrArea {
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
  img.qrGif {
    width: 100%;
    max-width: 736px;
    margin-right: 35px;
  }
}

.infoArea {
  max-width: 537px;
  ul {
    li {
      margin-bottom: 14px;
      p {
        font-size: 14px;
        font-weight: normal;
        color: $theme-dark-1;
        line-height: normal;
      }
    }
  }
}

.bookWidget {
  width: 440px;
  margin: 24px 0;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: rgba($theme-primary-light, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    img {
      background: $theme-primary;
      padding: 5px;
      display: block;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      margin: 0;
      margin-right: 13px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: $theme-dark;
    }
  }
  .bookWidgetButton {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background: $theme-primary;
    width: 113px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 4px;
  }
}

.cards {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .card {
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
    padding: 30px;
    width: 48.5%;
    .cardHead {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      img {
        height: 20px;
      }
      h3 {
        text-align: center;
        color: #3e5e6e;
        font-size: 20px;
        font-weight: 900;
        line-height: 90%;
      }
    }
    .cardBody {
      margin-top: 20px;
      ul {
        li {
          margin-bottom: 24px;
          text-align: center;
          svg {
            margin: 30px auto 13px;
            display: inline-block;
          }
          p {
            font-size: 14px;
            font-weight: normal;
            color: #3f6070;
            line-height: normal;
            text-align: left;
          }
        }
      }
      > img {
        width: 100%;
      }
    }
  }
}
