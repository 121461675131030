@import "../../../assets/styles/main.scss";

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
.Icon {
  width: 84px;
  height: 70px;
}
.Heading {
  margin-top: 24px;
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #0f3649;
}

.Description {
  margin-top: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #0f3649;
}
.ConfirmIcon {
  margin-top: 30px;
}
.Container {
  padding: 12px;
  text-align: center;
}
