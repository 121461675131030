@import "../../../../../assets/styles/main.scss";

.Container {
  width: 100%;
  margin-bottom: 16px;
}

.BlockLabel {
  display: block;
  font-size: $fs-small;
  font-weight: $fw-bold;
  line-height: 150%;
  margin-bottom: 8px;
}

.AppIconsUpload {
  display: flex;
  flex-direction: row;
}

.IOSAppIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 4px;
  border-radius: 16px;
  background-color: #ffffff;
}

.AndroidAppIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.AppIconText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  span {
    width: 112px;
    height: 16px;
    font-family: RedHatDisplay;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
  p {
    width: 112px;
    height: 42px;
    font-family: RedHatDisplay;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}

.wrapper {
  border-radius: $radius-primary;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  margin: 0 auto 16px;
  background-color: $light;
  height: 183px;
  width: 240px;
}

.input {
  display: none;
}

.inputZone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .addIcon {
      background-color: $primary;
      border-radius: 50%;
    }
    .text {
      color: $text-color-primary;
    }
  }
}

.imgWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .hoverContent {
      opacity: 1;
    }
  }
}

.hoverContent {
  opacity: 0.01;
  background-color: rgba($primary, 0.9);
  color: $text-color-white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  transition: $transition-basic;
  .hoverActions {
    display: flex;
    margin-top: 60px;
    padding: 0 24px;
    button {
      margin: 4px;
    }
  }
  .hoverText {
    font-size: $fs-xsmall;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.textWrapper {
  margin-top: 48px;
  position: relative;
  color: $text-color-grey;
  text-align: center;
  font-size: $fs-xsmall;
  font-weight: $fw-bold;
  width: 100%;
}

.text {
  transition: $transition-basic;
}

.addIcon {
  margin-top: 4px;
}

.description {
  display: block;
  margin-top: 40px;
  font-size: $fs-xsmall;
  font-weight: $fw-bold;
}

@media only screen and (min-width: $screen-xl) {
  .wrapper {
    margin-bottom: 0;
  }
}

.Invalid {
  border: $border-error;
}
