@import "../../../../../assets/styles/main.scss";

.Title {
  display: flex;
  align-items: center;
  font-size: $fs-p;
  font-weight: $fw-bold;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.Description {
  margin-top: 8px;
  font-size: 14px;
  color: #0f3649;
  margin-bottom: 24px;
}
.Select {
  font-size: 14px;
  margin-bottom: 27px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f3649;
}

.errorField {
  color: red;
  font-size: 12px;
  font-weight: bold;
  bottom: 0px;
  position: absolute;
}
.Select {
  font-size: 14px;
  margin-bottom: 27px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f3649;
}
.ToggleSwitchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.analyticsOptionContainer {
  position: relative;
  padding-bottom: 8px;
}
