.container {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding-bottom: 16px;
}

.box {
  width: 88px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  border: 1px solid transparent;
}

.logo {
  height: 18px;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  position: absolute;
  bottom: -23px;
  font-size: 11px;
  width: 100%;
  text-align: center;
}

.selected {
  color: #3196f6;
  border-color: #3196f6;
}
