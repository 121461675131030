@import "../../../assets/styles/main.scss";

#contactContainer {
  width: 240px;
  height: 210px;
  border-radius: 4px;
  background-color: rgba($color: #9e74ee, $alpha: 0.1);
  margin: 16px 0;
  align-self: center;
  padding: 16px;
  overflow: hidden;

  .contactContainer {
    .topRow {
      display: flex;
      align-items: center;

      .newContainer {
        width: 43px;
        height: 20px;
        border-radius: 2px;
        background-color: #9e74ee !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      .emoji {
        margin-left: 10px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e5e6e;
      margin-top: 10px;
    }
    .desc {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e5e6e;
      margin: 10px 0;
    }
    .btnText {
      color: #3e5e6e;
    }
  }
}
