.Step {
  display: flex;
  align-items: center;
}

.StepIcon {
  margin-right: 16px;
}

.Message {
  font-size: 70%;
  line-height: 150%;
}

.Skipper {
  font-size: 60%;
  font-weight: 700;
  margin-left: 32px;
  color: #3196f6;
  text-decoration: underline;
  cursor: pointer;
}
