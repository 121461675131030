.Switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cfd7db;
  transition: 0.3s;

  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    top: 1px;
    left: 1px;
    background-color: #fff;
    transition: 0.3s;
  }
}

.Switch input:checked + .Slider {
  background-color: #3e5e6e;

  &:before {
    transform: translateX(16px);
  }
}

.Slider.Round {
  border-radius: 15.5px;

  &:before {
    border-radius: 50%;
  }
}

.Label {
  margin-left: 8px;
  font-size: 70%;
  font-weight: 900;
}

.Container {
  display: flex;
  align-items: center;
}
