@import "../../../assets/styles/main.scss";

.navigationList {
  width: 240px;
  height: 100%;
  border-right: 1px solid #e7eaec;
  background-color: $white;

  a {
    display: flex;
    width: 240px;
    padding: 22px 16px;
    font-size: 14px;
    font-weight: $fw-medium;
    align-items: center;
    justify-content: space-between;
    transition: $transition-basic;
    color: #3e5e6e;
    cursor: pointer;
  }
  .navigationItem {
    cursor: pointer;
    &:link,
    &:visited {
      color: #3e5e6e;
    }
    &:hover,
    &:active {
      background-color: #f7f9f9;
      color: #3196f6;
      cursor: pointer;
    }
    &.active {
      background-color: #f7f9f9;
      color: #3196f6;
      cursor: pointer;
    }
  }

  .subNavigation {
    background-color: #f7f9f9;
  }

  .subNavigationItem {
    cursor: pointer;
    &:link,
    &:visited {
      color: #3e5e6e;
    }
    &:hover,
    &:active {
      color: #3196f6;
    }
    &.active {
      color: #3196f6;
    }
  }

  .arrowIcon {
    display: flex;

    &.rotateIcon {
      transform: rotate(180deg);
    }
  }

  .menuWrapper {
    li {
      position: relative;
      margin-left: 16px;
    }
    li:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #e7eaec;
      z-index: $z-index-top;
      margin: 0 16px;
    }
  }
}
