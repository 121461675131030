/* ============= DEFAULT THEME ================ */
/* ============================================ */

/* COLORS */
$theme-primary-light: #4da8ff !default;
$theme-primary: #3196f6 !default;
$theme-primary-dark: #1f81df !default;
$theme-secondary-light: #e66565 !default;
$theme-secondary: #e66565 !default;
$theme-secondary-dark: #e66565 !default;
$theme-accent-light: #3fc48a !default;
$theme-accent: #3fc48a !default;
$theme-accent-dark: #3fc48a !default;
$theme-black: #000000 !default;
$theme-dark: #0f3649 !default;
$theme-dark-1: #3e5e6e !default;
$theme-dark-2: #6d8694 !default;
$theme-grey: #9fafb6 !default;
$theme-grey-1: #cfd7db !default;
$theme-grey-2: #cfd7db !default;
$theme-light: #e7eaec !default;
$theme-light-1: #f3f5f6 !default;
$theme-light-2: #f7f9f9 !default;
$theme-info: #4da8ff !default;
$theme-white: #ffffff !default;
$theme-error: #e66565 !default;
$theme-warning: #4da8ff !default;
$theme-success: #3fc48a !default;
