@import "../../assets/styles/main.scss";

.DesignContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.DesignConfigContainerLeft {
  padding: 16px 16px 16px 16px;
  flex: 0 0 auto;
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.DesignConfigContainer {
  width: 340px;
  float: left;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MobileScreen {
  display: flex;
  height: 100vh;
  min-height: 700px;
  justify-content: space-between;
  position: relative;
}

.HeaderDropdown {
  position: relative;
  margin-bottom: 20px;
}

.SelectInput {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.Label {
  font-size: 12px;
  color: #0f3649;
  margin-bottom: 8px;
  font-weight: 500;
}

.ActiveItem {
  color: #3196f6;
}

.RightSection {
  display: flex;

  .arrowIcon {
    display: flex;
    margin-left: 8px;

    &.rotateIcon {
      transform: rotate(180deg);
    }
  }
}

.Seperator {
  border: 1px solid #e7eaec;
}

.menuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: $radius-primary;
  z-index: $z-index-top;
  box-shadow: $box-shadow-basic;
  margin-top: 8px;
  overflow: hidden;
  li:not(:last-of-type) {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: #e7eaec;
      z-index: $z-index-top;
    }
  }
}

.Invalid {
  border: $border-error;
}

.Disabled {
  color: #9fafb6 !important;
}

.menuActivator {
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 4px 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #204150;
}

.menuActivator,
.menuBtn {
  width: 100%;
  border: none;
  text-align: left;
  color: #3e5e6e;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Red Hat Display";
  padding: 24px 16px;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  cursor: pointer;
  &:active {
    color: $text-color-primary;
  }
}

.menuBtn {
  padding: 24px 16px;
  transition: $transition-basic;
  display: flex;
  justify-content: space-between;
  background-color: white;
  &:hover {
    color: $text-color-primary;
  }
}

.FormContainer {
  background-color: $theme-light-1;
  margin-top: 20px;
  padding: 20px;
  overflow-y: auto;
  max-height: 700px;
  form {
    margin-bottom: 150px;
  }
}
.inputWrapper {
  display: block;
}

.inputGroup,
.linkType {
  margin-bottom: 25px;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.InfoText {
  width: 100%;
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-color-dark-1;
}

.InfoGroup {
  display: flex;
  flex-direction: row;
  margin-top: 18x;
}

.Information {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 5px;
  padding: 2px;
}

.UploadContainer {
  display: flex;
  flex-direction: column;
}

.IconsUpload {
  display: flex;
  flex-direction: row;
}

.pickerStyle {
  margin-bottom: 10px;
}
