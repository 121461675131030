.Completion {
  width: 75%;
  margin: 200px auto 0;
  text-align: center;
}

.Heading {
  font-size: 120%;
  font-weight: 900;
  color: #3196f6;
  margin-bottom: 8px;
}

.InfoText {
  font-size: 16px;
  line-height: 150%;
  color: #0f3649;
}

.ButtonContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  >button:first-child {
    margin-right: 16px;
  }
}
.backIconContainer {
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  height: 17px;
  align-items: center;

  .backIconLabel {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3196f6;
  }

  .backIcon {
    display: flex;
    margin-right: 8px;
  }
}