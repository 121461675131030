@import "../../../../assets/styles/main.scss";
.ManageSubRouteContainer {
  height: calc(100% - 67px);
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.selectPlatformContainer {
  margin-bottom: 16px;
}

.selectPlatformContainerYoutube {
  margin-bottom: 16px;
  flex-direction: row;
}
.Heading {
  font-weight: $fw-bolder;
  line-height: 150%;
  margin-bottom: 16px;
  color: #3e5e6e;
}

.Paragraph {
  font-size: $fs-p;
  line-height: 150%;
  margin-bottom: 32px;
  color: #3e5e6e;
}

.subTitle {
  font-size: $fs-p;
  color: #3e5e6e;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.platformOption {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.ButtonContainer {
  margin-top: 44px;
}

.header {
  font-size: 14px;
  font-weight: $fw-bold;
  margin-bottom: 16px;
  color: #3e5e6e;
}

.RadioContainer {
  display: flex;
  margin-bottom: 25px;
}
.fieldWithTutorialContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RadioWrapper {
  display: flex;
  margin-right: 45px;
  align-items: center;
}

.infoTextContainer {
  display: flex;
}

.infoIcon {
  margin-right: 8px;
}

.csvInfoText {
  font-size: 12px;
  font-weight: $fw-normal;
  color: #3e5e6e;
}

.downloadLink {
  display: inline-block;
  color: #1f81df;
  cursor: pointer;
}

.YoutubeIcon {
  margin-left: 8px;
}
