@import "../../../assets/styles/main.scss";
.ManageSubRouteContainer {
  height: calc(100% - 67px);
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.PaymentOption {
  margin-bottom: 20px;
}

.HeadingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .Heading {
    font-size: 100%;
    font-weight: 900;
    color: #3e5e6e;
    margin-right: 12px;
  }
}

.Details {
  margin-top: 18px;
}

.Description {
  font-size: $fs-small;
  line-height: 150%;
  margin-bottom: 16px;
}

.paymentFormContainer {
  margin-top: 24px;
  margin-bottom: 90px;
}

.ButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  right: 0;
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonWrapper {
  position: relative;
  display: flex;
  height: 65px;
}

.errorField {
  color: red;
  font-size: 12px;
  padding-left: 15px;
  font-weight: bold;
}

.Info {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #3e5e6e;
}

.Warn {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-bottom: 16px;
  color: #e66565;
}
.ButtonGroup {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 370px;
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 16px 32px 32px 16px;
}
