@import "../../../assets/styles/main.scss";

.HeaderDropdown {
  position: relative;
  margin-bottom: 10px;
}
.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.Select {
  display: block;
  font-size: 70%;
  font-family: inherit;
  font-weight: 500;
  color: #9fafb6;
  padding: 12px 16px;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1),
    0 2px 11px 0 rgba(15, 54, 73, 0);
  appearance: none;

  /*Removes border*/
  -webkit-appearance: none;

  /*Removes default chrome and safari style*/
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.menuActivator {
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-weight: $fw-medium;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #204150;
}

.menuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: $radius-primary;
  z-index: $z-index-top;
  box-shadow: $box-shadow-basic;
  margin-top: 8px;
  // overflow: hidden;
  background: white;
  max-height: 300px;
  overflow: auto;
  li {
    padding: 0 16px;
    &:last-child {
      button {
        border-bottom: none !important;
      }
    }
  }
}

.Invalid {
  border: $border-error;
}

.menuActivator {
  padding-inline: 13px !important;
}

.menuActivator,
.menuBtn {
  padding: 10px 0;
  width: 100%;
  border: none;
  text-align: left;
  color: #3e5e6e;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 200ms ease-in-out;
  font-weight: $fw-medium;
  border-bottom: 1px solid #e7eaec;
  cursor: pointer;
  &:active {
    color: $text-color-primary;
  }
}

.arrowIcon {
  display: flex;

  &.rotateIcon {
    transform: rotate(180deg);
  }
}

.menuBtn {
  transition: $transition-basic;
  background-color: white;
  font-weight: bold;
  color: #9fafb6;
}

.displayValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.time {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #9fafb6;
  margin-top: 2px;
}

.selected {
  color: #3196f6;
}

.SelectInput {
  font-size: 14px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.Empty {
  color: #9fafb6;
}

.Disable {
  pointer-events: none;
  color: #9fafb6;
}
