.StepsToggler {
  display: inline-block;
  font-size: 60%;
  font-weight: 500;
  cursor: pointer;
}

.Dark {
  color: #0f3649;
}

.Light {
  color: #3196f6;
}