.bg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
}

.logo {
  height: 28px;
  width: 140px;
  margin: 29px 0px 0px 50px;
}

.formWrapper {
  display: flex;
  justify-content: center;
}

.forgotPasswordFormContainer {
  margin-top: 110px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.title {
  font-family: Poppins Bold;
  font-size: 48px;
  color: #211065;
  margin-bottom: 32px;
  text-align: center;
}

.subTitle {
  font-family: Poppins Medium;
  font-size: 18px;
  color: #211065;
  margin-bottom: 56px;
  text-align: center;
}

.backContainer {
  font-family: Poppins SemiBold;
  font-size: 14px;
  margin-top: 36px;
  text-align: center;

  .hyperLink {
    color: #3196f6;
    cursor: pointer;
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  padding: 10px 41px 10px 10px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 8px;
  background-color: rgba(230, 101, 101, 0.16);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  .errorIcon {
    margin: 10px;
  }
  .errorText {
    margin-left: 10px;
    color: #3e5e6e;
  }
}
