@import "../../../assets/styles/main.scss";

.Container {
  height:100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.backIconContainer {
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  height: 17px;
  align-items: center;

  .backIconLabel {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3196f6;
  }

  .backIcon {
    display: flex;
    margin-right: 8px;
  }
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 8px;
  margin-top: 16px;
  color: #3e5e6e;
}

.description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #3e5e6e;
  margin-bottom: 16px;
}

.Paragraph {
  font-size: 14px;
  line-height: 1.71;
  font-weight: $fw-normal;
  margin-bottom: 32px;
  color: #3e5e6e;
}
.CheckboxList {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .CheckboxGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    justify-content: space-between;
  }
}

.EmailGroup {
  display: flex;
  flex-direction: column;

  .EmailGroupTitle {
    font-size: 14px;
    font-weight: bold;
    color: #0f3649;
  }

  .emailList {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .removeIcon {
      cursor: pointer;
      display: flex;
      margin-bottom: 8px;
      margin-left: 10px;
    }
    .emailInput {
      width: 100%;
    }
    .inputMargin {
      margin-right: 24px;
    }
  }
  .newEmailWrapper {
    display: flex;
    justify-content: flex-end;

    .newEmailContainer {
      cursor: pointer;
      display: inline-flex;

      .plusIconLabel {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: #3196f6;
      }

      .plusIcon {
        display: flex;
        margin-right: 6px;
      }
    }
    .newEmailMargin {
      margin-right: 24px;
    }
  }
}
.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0px;
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}
