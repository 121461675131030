@import "../../../assets/styles/main.scss";


.hueRangePicker {
    position: relative;
    margin-right: 16px;
    height: 12px;
    width: 216px;
    border: 1px solid #fff;
    background: linear-gradient(
      90deg,
      red 0,
      #ff0 17%,
      #0f0 33%,
      #0ff 50%,
      #00f 67%,
      #f0f 83%,
      red
    );
    border-radius: 32px;
    cursor: pointer;
    user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .hueRangePicker > .handle {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    height: 14px;
    width: 14px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 0 1px inset;
    pointer-events: none;
  }
  
  .hueRangePicker.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }