@import "../../../assets/styles/main.scss";

.ManageSubNavigation {
  min-width: 272px;
  float: left;
  height: 100vh;
  border-right: 1px solid #e7eaec;
}

.disable {
  display: flex;
  padding: 22px 16px;
  font-size: 70%;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  cursor: auto;
  color: #9fafb6;
  background-color: white;
}

.valid {
  display: flex;
  padding: 22px 16px;
  font-size: 70%;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;

  &.valid {
    color: #3e5e6e;
    background-color: white;
  }
  &.disable {
    color: #9fafb6;
    background-color: white;
  }

  &:hover {
    background-color: #f7f9f9;
  }

  &:active {
    background-color: #f7f9f9;
    color: #3196f6;
  }

  &.active {
    background-color: #f7f9f9;
    color: #3196f6;
  }
}
@media screen and (max-width: 720px) {
  .ManageSubNavigation {
    display: none;
  }
}
