@import "../../../assets/styles/main.scss";

.contentTable {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  th {
    text-align: left;
    &:first-child {
      width: auto;
      margin-bottom: 4px;
    }

    .tableHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      padding: 14px 8px 14px 4px;
      text-align: left;
      color: #0f3649;
    }

    .filter {
      display: flex;
      flex-direction: column;
      margin-top: 4px;
    }
  }

  .tableCol {
    display: flex;
    justify-content: space-between;
  }

  td {
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
    border-left: $border-table;
    color: $theme-dark;

    &:last-child {
      border-left: $border-table;
    }
    &:first-child {
      border-left: 0;
      padding-left: 16px;
    }
    a {
      padding: 20px 16px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  tbody {
    border-radius: $radius-primary;
    box-shadow: 0 0 0 1px $theme-grey-2;
    tr {
      width: 100%;
      height: 56px;
      td:first-child {
        width: auto;
      }
      &:not(:last-child) td {
        border-bottom: $border-table;
      }
    }
  }
}

.searchContainer {
  display: flex;
  justify-content: flex-end;
  .search {
    width: 200px;
  }
}
