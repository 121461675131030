@import "../../../assets/styles/main.scss";
@font-face {
  font-family: "Red Hat Display Bold";
  src: url("../../../assets/fonts/RedHatDisplay-Bold.ttf") format("truetype");
}

.Label {
  font-size: 12px;
  color: #0f3649;
  margin-bottom: 8px;
  font-weight: 500;
}

.Seperator {
  border: 1px solid #e7eaec;
}

.DesignConfigContainer {
  flex: 0 0 auto;
}

.HeaderDropdown {
  position: relative;
  margin-bottom: 20px;
}

.Select {
  display: block;
  font-size: 70%;
  font-family: inherit;
  font-weight: 500;
  color: #9fafb6;
  padding: 12px 16px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1),
    0 2px 11px 0 rgba(15, 54, 73, 0);
  appearance: none;

  /*Removes border*/
  -webkit-appearance: none;

  /*Removes default chrome and safari style*/
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.RightSection {
  display: flex;

  .arrowIcon {
    display: flex;
    margin-left: 8px;

    &.rotateIcon {
      transform: rotate(180deg);
    }
  }
}

.menuActivator {
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 4px 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #204150;
}

.menuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: $radius-primary;
  z-index: $z-index-top;
  box-shadow: $box-shadow-basic;
  margin-top: 8px;
  overflow: hidden;
  li:not(:last-of-type) {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: #e7eaec;
      z-index: $z-index-top;
    }
  }
}

.Invalid {
  border: $border-error;
}

.Disabled {
  color: #9fafb6 !important;
}

.menuActivator,
.menuBtn {
  width: 100%;
  border: none;
  text-align: left;
  color: #3e5e6e;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Red Hat Display";
  padding: 24px 16px;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  cursor: pointer;
  &:active {
    color: $text-color-primary;
  }
}

.menuBtn {
  padding: 24px 16px;
  transition: $transition-basic;
  display: flex;
  justify-content: space-between;
  background-color: white;
  &:hover {
    color: $text-color-primary;
  }
}

.SelectInput {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}
.ActiveItem {
  color: #3196f6;
}
