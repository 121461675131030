.error {
  display: flex;
  margin: 5px 0;

  span {
    color: red;
    font-size: 12px;
    font-weight: bold;
  }
}
