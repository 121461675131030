.info {
  margin: 0 0 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f3649;
}

.infoText {
  font-weight: bold;
  color: #ff8360;
}
.btnContainer{
  display: flex;
  flex-direction: row;
  flex:1;
}
.Close{
  position: absolute;
  right: 0;
  top: 0;
  margin: 2px 10px;
  img{
    width: 10px;
    height: 10px;
    object-fit: contain;
  }
}
