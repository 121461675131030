@import "../../../assets/styles/main.scss";

.EngageSubRouteContainer {
  height:100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  color: #3e5e6e;
}
.Info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  margin-top: 16px;
  color: #3e5e6e;
}

.LanguageContainer {
  padding: 16px 0;
  .BlockLabel {
    display: block;
    font-size: 14px;
    font-weight: $fw-bold;
    line-height: 150%;
    color: #3e5e6e;
    margin-bottom: 8px;
  }
}

.MobileScreen {
  margin: 32px;
  justify-content: center;
  padding-bottom: 24px;
}

.EmptyNav {
  width: 72px;
  height: 72px;
  margin: 4px;
}

.Nav {
  float: right;
  margin: 8px 0px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.ActiveBorder {
  border: solid 1px #3e5e6e !important;
}

.Label {
  width: 43px;
  height: 16px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.Active {
  color: #3e5e6e;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 392px;
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 16px;
  box-shadow: 0 2px 15px 0 rgba(22, 33, 43, 0.12);
}
.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.ModalBody {
  margin-top: 16px;

  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }

  .ConfirmIcon {
    margin: 8px;
  }
}

.ModalFooter {
  .ModalButtonContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    > button:first-child {
      margin-right: 20px;
    }
  }
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.ButtonGroup {
  display: flex;
  justify-content: center;
  background: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;

  .DiscardButton {
    margin-right: 10px;
  }
}
