
.popover {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  margin-top: -25px;
  margin-left: 10px;
  width: 248px !important;
  height: 260px !important;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;

  &:after {
    content: 'Color Picker';
    position: absolute;
    top: 12px;
    left: 16px;
    font-size: 14px;
    font-weight: bold;
  }
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;


}

.colorSquare {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 5px;
}

.inputColor {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  width: 70px;
  height: 24px;
}

.colorContainer {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
}

.colorPickerInput {
  position: absolute;
  bottom: 18px;
  left: 16px;
  display: flex;
  align-items: center;
}

.chromePicker{
  margin: 36px 16px 0px 16px !important;
  padding: 0 !important;
  height: initial !important;
  width: initial !important;
  border-radius: 4px !important;
  box-shadow:none !important;
  display: flex;
    flex-direction: column;
}

.chromePicker > div:first-child{
  height: 150px !important;
  width: 100% !important;
  border: solid 2px #e6e6e6;
}

.hueSlider{
  width: 100%;;
  position: absolute;
    bottom: 50px;
    left: 16px;
    display: flex;
    align-items: center;
}

