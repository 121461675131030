.newPasswordCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 12px 0 rgba(144, 138, 168, 0.16);
  border-radius: 24px;
  padding: 106.7px 94px 78.3px;
  background-color: rgba(255, 255, 255, 0.8);

  form {
    width: 307px;
  }
}

.bg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  background-position: bottom;
  background-color: #d5e9fd;
  display: flex;
  align-items: center;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
}

.logo {
  height: 39px;
  width: 196px;
}

.title {
  color: #3e5e6e;
  font-size: 26px;
  font-weight: 900;
  text-align: left;
  margin-bottom: 12px;
  width: 100%;
}

.subTitle {
  color: #3e5e6e;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 42px;
  width: 100%;
}

.mainContainer {
  width: 534px;
  margin: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.backContainer {
  font-family: Poppins SemiBold;
  font-size: 14px;
  margin-top: 28px;
  text-align: center;

  .hyperLink {
    color: #3196f6;
    cursor: pointer;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  padding: 10px 62px 10px 10px;
  font-family: Poppins Medium;
  font-size: 12px;
  font-weight: normal;
  border-radius: 8px;
  background-color: #d5e9fd;
  width: 346px;
  margin-bottom: 40px;

  .infoIcon {
    margin: 10px;
  }

  .infoText {
    margin-left: 10px;
    color: #1a0c50;
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  padding: 10px 41px 10px 10px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 8px;
  background-color: rgba(230, 101, 101, 0.16);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  .errorIcon {
    margin: 10px;
  }
  .errorText {
    margin-left: 10px;
    color: #3e5e6e;
  }
}
.inputWrapper {
  position: relative;
  span {
    position: absolute;
    top: 35px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
.createPasswordTitle {
  font-size: 26px;
  color: #3e5e6e;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}
.createPasswordDesc {
  font-size: 16px;
  color: #3e5e6e;
  margin-bottom: 16px;
  font-weight: 400;
}
