@import "../../../assets/styles/main.scss";

.Container {
  padding: 16px;
  background-color: $text-color-light-1;
  width: 240px;
  margin: 0 auto;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.DesignConfigContainer {
  height: 100vh;
  width: 272px;
  float: left;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
}

.componentsContainer {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  height: 100%;
}

.MobileScreen {
  display: flex;
  height: 100vh;
  min-height: 700px;
  justify-content: space-between;
  position: relative;
  overflow: auto;
}

.Nav {
  margin: 8px 0px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.ActiveBorder {
  border: solid 1px #3e5e6e !important;
}

.Label {
  width: 43px;
  height: 16px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.Active {
  color: #3e5e6e;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin: 32px 32px;
}

.ColorContainer {
  padding: 16px;
  background-color: #f3f5f6;
  width: 240px;
  margin: 16px auto;
}

.ColorList {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ColorItem {
  display: flex;
  flex-direction: row;
  width: 206px;
  height: 66px;
  justify-content: space-between;
  align-items: center;
  .BlockGroup {
    display: flex;
    flex-direction: column;
  }
}

.ColorName {
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.ColorDescription {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d8694;
}

hr {
  height: 2px;
  border: solid 1px #e7eaec;
}

.SectionGroup {
  display: flex;
  flex-direction: row;
}

.Tab {
  float: right;
  position: absolute;
  top: calc(50% - (539px / 2));
  left: calc(50% - 261px);
  z-index: 8;
  li {
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    width: 105px;
    height: 32px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.TabLabel {
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.TabActiveBorder {
  border: solid 1px #3e5e6e !important;
}

.TabActive {
  color: #3e5e6e;
}

// Drop container
