.ManageSubRouteContainer {
  height: calc(100% - 67px);
  width: 100%;
  padding: 32px 32px 0px 32px;
  overflow: auto;
}

.FieldSet {
  border: none;
  margin-bottom: 40px;
}

.LegendContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  .Legend {
    font-size: 100%;
    font-weight: 900;
    color: #3e5e6e;
    margin-right: 12px;
  }
}

.Description {
  font-size: 70%;
  margin-bottom: 24px;
  color: #3e5e6e;
}

.NotSupportedConfigDescription {
  font-size: 70%;
  margin-top: 32px;
  margin-bottom: 24px;
  color: #3e5e6e;
}

.ConfigContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 32px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
}

.ConfigWrapperWithDescription {
  display: flex;
  align-items: center;
  background-color: #f7f9f9;
  height: 95px;
  padding: 0 16px;
  border-radius: 6px;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 0;
}

.ItemContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
}

.Item {
  flex: 1;

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 8px;
    color: #6d8694;
    padding-right: 10px;

    .blueText {
      color: #0f3649;
    }
  }

  span {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #6d8694;
  }

  .blueText {
    color: #0f3649;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ImgFlex {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  width: 100%;
  margin: 10px 0;

  > div {
    width: 100%;
    border: solid 2px #9fafb6;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.ImgSmall {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  width: 100%;
  margin: 10px 0;

  > div {
    width: 50%;
    border: solid 2px #9fafb6;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.ModalTitle {
  .ModalTitleText {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
    text-align: left;
  }
}

.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 32px;
  width: 100%;

  .ModalBodyText {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: #3e5e6e;

    span.blueText {
      color: #4da8ff;
      font-weight: 500;
    }
  }
}

.ModalForm {
  margin-top: 20px;
}

.ModalFooter {
  width: 100%;
  display: flex;
  flex: 1;

  .footerButtonContainer {
    width: 100%;
    cursor: pointer;
    display: inline-flex;
    flex: 1;

    .pricingFeature {
      width: 100%;
      height: 16px;
      margin: 0 11px 0 0;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3196f6;
    }
    .maybeLater {
      width: 100%;
      height: 16px;
      margin: 0 0 0 11px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #3196f6;
    }
  }
}

.ModalFooterButton {
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  text-align: left;
}

.ConfigWrapper {
  display: flex;
  align-items: center;
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
