@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
}
.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.BlockInput {
  display: block;
  width: 100%;
  height: 40px;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-family: inherit;
  font-size: $fs-small;
  font-weight: $fw-medium;
  border: $border-input-default;
  &::placeholder {
    color: $text-color-grey;
  }
}

.Upload {
  cursor: pointer;

  position: relative;
}

.UploadCustom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-family: inherit;
  font-size: $fs-small;
  font-weight: $fw-medium;
  border: $border-input-default;
  &::placeholder {
    color: $text-color-grey;
    font-size: 14px;
  }
}

.UploadCustomPlaceholder {
  color: $text-color-grey;
  font-size: 14px;
}

.UploadCustomText {
  font-size: 14px;
  color: #3e5e6e;
  font-weight: $fw-medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UploadDefault {
  display: none;
}

.Invalid {
  border: $border-error;
}
