@import "../../../assets/styles/main.scss";

.AppNameContainer {
  padding: 16px;
  background-color: $text-color-light-1;
}

.AppNameTitle {
  font-size: 70%;
  font-weight: 700;
}

.GrowthInfoText {
  width: 186px;
  object-fit: contain;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  margin-bottom:8px;
  a {
    font-weight: bold;
    color: #ff8360
  }
}
.InfoText {
  width: 186px;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.InfoGroup {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.AppIconContainer {
  margin: 16px 0;
  padding: 16px;
  background-color: $text-color-light-1;
}

.NavigationBarLogoContainer {
  margin: 16px 0;
  padding: 16px;
  background-color: $text-color-light-1;
}

.LanguageContainer {
  padding: 16px;
  background-color: $text-color-light-1;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: 150%;
  color: #3e5e6e;
  margin-bottom: 8px;
}

.UploadContainer {
  display: flex;
  flex-direction: column;
}

.AppIconsUpload {
  display: flex;
  flex-direction: row;
}

.IOSAppIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 4px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  background-color: #ffffff;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  span {
    width: 62px;
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-color-grey;
  }
}

.AndroidAppIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  span {
    width: 62px;
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-color-grey;
  }
}

.AppIconText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}
.AppIconTextTitle {
  width: 112px;
  height: 16px;
  font-size: 12px;
  font-weight: $fw-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}
.AppIconTextDesc {
  width: 112px;
  height: 42px;
  font-size: 11px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #3e5e6e;
}
.Information {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 8px;
  margin-top: 3px;
}

.TheChangesYouMake {
  width: 186px;
  height: 32px;
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.BlockInput {
  display: block;
  width: 100%;
  height: 40px;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-family: inherit;
  font-size: $fs-small;
  font-weight: $fw-medium;
  border: $border-input-default;
  &::placeholder {
    color: $text-color-grey;
  }
}

.Upload {
  position: relative;
}

.UploadCustom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-family: inherit;
  font-size: $fs-small;
  font-weight: $fw-medium;
  border: $border-input-default;
  &::placeholder {
    color: $text-color-grey;
  }
}

.UploadDefault {
  display: none;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin: 32px 32px;
  z-index: 11;
}

.DesignConfigContainer {
  height: 100%;
  width: 272px;
  float: left;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
}

.componentsContainer {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  height: 100%;
}

.MobileScreen {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 700px;
}
.EmptyNav {
  width: 72px;
  height: 72px;
  margin: 4px;
}

.Nav {
  margin: 8px 0px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.ActiveBorder {
  border: solid 1px #3e5e6e !important;
}

.Label {
  width: 43px;
  height: 16px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.Active {
  color: #3e5e6e;
}
