.optionItem {
  margin: 24px 0;
  background-color: #f3f5f6;
  padding: 16px;
  border-radius: 4px;
}

.optionItemTitle {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  margin-bottom: 16px;
}

.optionItemDescriptionArea {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.optionItemDescription {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.infoIcon {
  margin-right: 8px;
}
