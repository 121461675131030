.PaymentOption {
  margin-bottom: 20px;
}

.Details {
  margin-top: 18px;
  margin-bottom: 40px;
}

.Description {
  font-size: 70%;
  line-height: 150%;
  margin-bottom: 16px;
}
