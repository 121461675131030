/* ============= MOWICO THEME ================ */
/* ============================================ */

/* COLORS */
$theme-primary-light: #4da8ff;
$theme-primary: #3196f6;
$theme-primary-dark: #1f81df;
$theme-dark: #0f3649;
$theme-dark-1: #3e5e6e;
$theme-dark-2: #6d8694;
$theme-grey: #9fafb6;
$theme-grey-1: #cfd7db;
$theme-light: #e7eaec;
$theme-light-1: #f3f5f6;
$theme-light-2: #f7f9f9;
$theme-white: #ffffff;
$theme-error: #e66565;
$theme-success: #3fc48a;
$theme-disabled: #bbbbbb;

/* FONTS */
$theme-font-family: "Red Hat Display", sans-serif;
