@import "../../../../../assets/styles/main.scss";

.PushNotificationOptions {
  border-bottom: $border-light;
  margin-bottom: 32px;
}

.Title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: $fw-bold;
  margin-bottom: 8px;
  color: #3e5e6e;
}
.Select {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f3649;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  pointer-events:none;
}

.infoArea {
  margin-bottom: 24px;
  background-color: #f3f5f6;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .infoIcon {
    margin-right: 8px;
  }
  .infoDescription {
    font-size: 12px;
    color: #3e5e6e;
    a {
      font-weight: bold;
      color: #1f81df;
      margin: 0 2px;
    }
  }
}
