.root {
  align-items: center;
  display: flex;
  padding-inline-end: 8px;
  margin-bottom: 12px;
}

.expandIconWrapper {
  height: 24px;
  width: 24px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
  > div {
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.button {
  cursor: pointer;
  font-family: "Red Hat Display", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3196f6;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  width: 100%;
  padding-inline-start: 8px;
  height: 40px;
  margin-left: 5px;
  padding: 11px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  p {
    font-family: "Red Hat Display";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .categoryHoverList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
