@import "../../../assets/styles/main.scss";
.Container {
  padding: 16px;
  background-color: $text-color-light-1;
  width: 240px;
  margin: 0 auto;
  align-items: center;
}

.content {
  height: 100%;
  justify-content: space-between;
  // overflow: auto;
  width: calc(100% - 300px);

  .contentHead {
    padding: 25px;
    h4 {
      font-size: 20px;
      font-weight: 900;
      color: $theme-dark-1;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      margin-top: 13px;
    }
  }

  .contentBody {
    max-width: 491px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contentBodyTitle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
    }
    h5 {
      font-size: 16px;
      font-weight: bold;
      color: $theme-dark-1;
    }
  }
}

.upgrade {
  width: 60%;
  padding-left:25px;

}
.button {
  cursor: pointer;
  font-family: "Red Hat Display", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3196f6;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  img {
    margin: 20px 5px 20px 20px;

  }
}

.categories {
  width: 100%;
  margin: 20px 0;
  height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 63vh;
  padding: 10px 15px;
  padding-left: 20px;
  > div {
    > ul {
      padding-bottom: 100px !important;
    }
  }
  .categoryItem {
    overflow: visible !important;
    position: relative;

    .item {
      display: flex;
      max-width: 497px;
      .categoryName {
        display: block;
        width: 100%;
        padding: 11px 12px;
        border-radius: 4px;
        box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        p {
          max-width: 300px;
          display: inline-block;
          justify-content: space-between;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }

      }

      span {
        cursor: pointer;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.ButtonContainer {
  bottom: 0;
  right: 0;
}

.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $theme-dark-1;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 38px;
  text-align: center;
  line-height: 1;
  width: 100%;

  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $theme-dark-1;
  }

  h4 {
    text-align: left !important;
    font-size: 20px;
    font-weight: 900;
    color: $theme-dark-1;
  }

  .ModalForm {
    margin: 25px 0 35px;
  }
}

.ModalFooter {
  width: 100%;
  .ButtonContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    > button:first-child {
      margin-right: 20px;
    }
  }
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.draggingSource {
  opacity: 0.3;
  // transition: all .3s ease-in-out;
}

.dropTarget {
  padding-bottom: 20px;
}

.placeholderContainer {
  position: relative;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: 150%;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #3e5e6e;

}
