@import "../../../../assets/styles/main.scss";
.ManageSubRouteContainer {
  height: calc(100% - 67px);
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 16px;
  color: #3e5e6e;
}

.Paragraph {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  font-weight: $fw-normal;
  margin-bottom: 30px;
  color: #3e5e6e;
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.VerificationErrorSummary {
  margin-top: 24px;
  font-size: 14px;
  line-height: 150%;
  color: #3e5e6e;
}

.backIconContainer {
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  width: 74px;
  height: 17px;
  align-items: center;

  .backIconLabel {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3196f6;
  }

  .backIcon {
    display: flex;
    margin-right: 8px;
  }
}

.tryAgainButtonContainer {
  margin-right: 16px;
}

.mailLink {
  color: #3196f6;
  cursor: pointer;
  margin-left: 5px;
}
