.EngageSubRouteContainer {
  height:100%;
  width: 100%;
  padding: 32px 32px 0px 32px;
  overflow: auto;
}

.Container {
  padding-bottom: 32px;
}

.FieldSet {
  border: none;
  margin-bottom: 40px;
}

.Legend {
  font-size: 100%;
  font-weight: 900;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.Description {
  font-size: 70%;
  color: #3e5e6e;
  margin-bottom: 24px;
}

.NotSupportedConfigDescription {
  font-size: 70%;
  margin-top: 32px;
  margin-bottom: 24px;
  color: #3e5e6e;
}

.ConfigContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 32px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.ButtonGroup {
  display: flex;
  justify-content: center;
  background: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;

  .DiscardButton {
    margin-right: 10px;
  }
}

.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.ModalBody {
  margin-top: 16px;

  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }

  .ConfirmIcon {
    margin: 8px;
  }
}

.ModalFooter {
  .ButtonContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    > button:first-child {
      margin-right: 20px;
    }
  }
}

.ConfigWrapper {
  display: flex;
  align-items: center;
}
.ConfigWrapperWithDescription {
  display: flex;
  align-items: center;
  background-color: #f7f9f9;
  height: 95px;
  padding: 0 16px;
  border-radius: 6px;
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
