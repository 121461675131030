@import "../../../../../assets/styles/main.scss";

.EngageSubRouteContainer {
  height:100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  color: #3e5e6e;
}
.Info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  margin-top: 16px;
  color: #3e5e6e;
}

.backIconContainer {
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  width: 74px;
  height: 17px;
  align-items: center;

  .backIconLabel {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3196f6;
  }

  .backIcon {
    display: flex;
    margin-right: 8px;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 392px;
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 16px;
  box-shadow: 0 2px 15px 0 rgba(22, 33, 43, 0.12);
}

.languageActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableWrapper {
}

.actionsWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px;
  background: $white;
  margin-left: 240px;
  box-shadow: $box-shadow-basic;
  button:first-child {
    margin-right: 16px;
  }
}

.searchContainer {
  display: flex;
  justify-content: flex-end;
  .search {
    width: 200px;
  }
}
