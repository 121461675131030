@import "../../../assets/styles/main.scss";

.MainContainer {
  display: flex;
  align-items: center;
}

/* The container */
.Container {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
}

/* Hide the browser's default radio button */
.Container input {
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  border: 2px solid $darker;
}

/* On mouse-over, add a grey background color */
.Container:hover input ~ .Checkmark {
  background-color: lighten($primary, 50%);
}

/* When the radio button is checked, add a blue background */
.Container input:checked ~ .Checkmark {
  background-color: $white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.Container input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Container .Checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $darker;
}

.Label {
  margin-left: 12px;
  font-size: 14px;
  font-weight: $fw-medium;
  color: #3e5e6e;
}

.DisabledContainer {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
}

/* Hide the browser's default radio button */
.DisabledContainer input {
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.DisabledCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  border: 2px solid #6d8694;
}

/* On mouse-over, add a grey background color */
.DisabledContainer:hover input ~ .DisabledCheckmark {
  background-color: lighten($primary, 50%);
}

/* When the radio button is checked, add a blue background */
.DisabledContainer input:checked ~ .DisabledCheckmark {
  background-color: $white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.DisabledCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.DisabledContainer input:checked ~ .DisabledCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.DisabledContainer .DisabledCheckmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6d8694;
}

.DisabledLabel {
  margin-left: 12px;
  font-size: 14px;
  font-weight: $fw-medium;
  color: #6d8694;
}
