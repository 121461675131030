.card {
  box-shadow: 0 3px 12px 0 rgba(144, 138, 168, 0.16);
  border-radius: 24px;
  padding: 96px 114px 96px 114px;
  background-color: rgba(255, 255, 255, 0.8);
}

.errorMessage {
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  margin-top: 8px;
}

.bg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  background-position: bottom;
  background-color: #d5e9fd;
  display: flex;
  align-items: center;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}

.logo {
  height: 39px;
  width: 196px;
}

.mainContainer {
  width: 534px;
  margin: auto;
}

.forgotPasswordContainer {
  margin-bottom: 40px;
  text-align: left;

  .hyperLink {
    font-family: Poppins Medium;
    font-size: 12px;
    color: #3195f5;
    cursor: pointer;
  }
}

.signUpText {
  font-family: Poppins Medium;
  font-size: 12px;
  color: #1d5993;
  margin-top: 64px;
  text-align: center;

  a {
    color: #3195f5;
    cursor: pointer;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.successContainer {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  font-family: Poppins Medium;
  font-size: 12px;
  font-weight: normal;
  border-radius: 16px;
  background-color: #d5ffef;
  width: 100%;
  margin-bottom: 20px;

  .successIcon {
    height: 32px;
    width: 32px;
  }

  .successText {
    margin-left: 8px;
    color: #1a0c50;
  }
}
.inputWrapper {
  position: relative;
  span {
    position: absolute;
    top: 35px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
