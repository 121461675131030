@import "../../../assets/styles/main.scss";

.MainContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

/* The Container */

.Container {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

/* Hide the browser's default checkbox */

/* Create a custom checkbox */

.Checkmark {
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #3e5e6e;
}

/* On mouse-over, add a grey background color */

.Container {
  &:hover input ~ .Checkmark {
    background-color: #eee;
  }

  input:checked ~ .Checkmark {
    background-color: #3e5e6e;
  }

  input:disabled ~ .Checkmark {
    background-color: #c8d2d6;
    border: 2px solid transparent;
  }
}

/* When the checkbox is checked, add a blue background */

/* Create the Checkmark/indicator (hidden when not checked) */

.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.CustomCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the Checkmark when checked */

.Container {
  input:checked ~ .Checkmark:after {
    display: block;
  }

  .Checkmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .CustomCheckmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);


  }
}

/* Style the Checkmark/indicator */

.Label {
  margin-left: 12px;
  color: #3e5e6e;
  font-size: 14px;
  font-weight: $fw-bold;
  margin-top: 4px;
}

.DisabledLabel {
  color: #9fafb6;
}
.Description {
  font-size: 13px;
  margin-top: 4px;
}
