@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .backIconContainer {
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
    height: 17px;
    align-items: center;

    .backIconLabel {
      display: flex;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3196f6;
    }

    .backIcon {
      display: flex;
      margin-right: 8px;
    }
  }

  .Heading {
    display: flex;
    margin-bottom: 8px;
    margin-top: 30px;
    .rocketIcon {
      width: 55px;
      height: 55px;
      margin-right: 14px;
      margin-top: 4px;
    }
  }

  .HeadingTextArea {
    display: flex;
    flex-direction: column;
    .HeadingText {
      font-size: 20px;
      font-weight: bold;
      color: #3e5e6e;
    }
    .Paragraph {
      margin-top: 16px !important;
      margin-bottom: 0 !important;
    }
  }

  .infoIcon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
  }

  .ParagraphText {
    font-size: 14px;
    line-height: 1.71;
    font-weight: $fw-normal;
    margin-bottom: 16px;
    color: #3e5e6e;
  }

  .ParagraphTextBold {
    font-weight: bold;
    margin: 0 4px;
    color: #1f81df;
  }

  .paragraphLink {
    color: #1f81df;
    cursor: pointer;
  }

  .Paragraph {
    font-size: 14px;
    line-height: 1.71;
    font-weight: $fw-normal;
    margin-bottom: 32px;
    color: #3e5e6e;
    display: flex;
    align-items: center;
  }
  .schedulerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 44px;
    margin-left: 80px;
    .time {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 90px;
      margin-right: 38px;
      .timeLabel {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        color: #3e5e6e;
      }
      .timeIcon {
        display: flex;
        margin-right: 4px;
      }
    }
  }
}

.modal {
  padding: 10px;
  .modalHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      font-size: 20px;
      font-weight: 900;
      color: #3e5e6e;
    }
    button {
      border: none !important;
      background: transparent !important;
      cursor: pointer;
      .cancelIcon {
        height: 20px;
        width: 20px;
      }
    }
  }
  .settingsIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    float: left;
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;
      &:before {
        content: "";
        position: absolute;
        width: 3px;
        height: 3px;
        background: #3e5e6e;
        left: 0;
        top: 8px;
        border-radius: 50%;
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      p {
        font-size: 14px;
        color: #3e5e6e;
        line-height: 20px;
      }
    }
  }
}
