@import "../../../assets/styles/main.scss";

.Container {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
}
.marginBottom {
  margin-bottom: 24px;
}
.BlockInput {
  display: block;
  width: 100%;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-family: inherit;
  font-size: 14px;
  color: #3e5e6e;
  font-weight: $fw-medium;
  border: $border-input-default;
  &:disabled {
    background-color: #cfd7db;
  }
  &:focus {
    border: $border-active;
  }
  &::placeholder {
    color: #9fafb6;
  }
}

.Select {
  display: block;
  width: 100%;
  background-color: $white;
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 11px 16px;
  font-size: $fs-small;
  font-weight: $fw-medium;
  font-weight: 500;
  border: none;
  color: #9fafb6;
  height: 40px;
}

.Textarea {
  width: 100% !important;
  resize: none;
}

.Invalid {
  border: $border-error !important;
}

.Valid {
}

.emojiToggle {
  position: absolute;
  right: 5px;
  bottom: 0;
  border: none !important;
  background: none !important;
  cursor: pointer;
}
