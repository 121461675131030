.ManageSubRouteContainer {
  height: calc(100% - 67px);
  width: 100%;
  padding: 32px;
  overflow: auto;
}
.engagementFormContainer {
  margin-top: 24px;
  margin-bottom: 90px;
}

.HeadingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .Heading {
    font-size: 100%;
    font-weight: 900;
    color: #3e5e6e;
    margin-right: 12px;
  }
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.errorField {
  color: red;
  font-size: 12px;
  padding-left: 15px;
  font-weight: bold;
}

.ButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  right: 0;
}
.ButtonWrapper {
  position: relative;
  display: flex;
  height: 65px;
}
.ButtonGroup {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 370px;
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 16px 32px 32px 16px;
}
