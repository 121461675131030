@import "./assets/styles/main.scss";

/* ======================================================= */
/* ============== RESET AND/OR NORMALIZE ================= */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  background-color: $white;
  color: $text-color-dark-2;
  font-family: $ff-primary;
  font-weight: $fw-normal;
  font-size: $fs-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.app_container {
  display: flex;
  min-height: 700px;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.pt-8 {
  padding-top: 8px;
}

.pa-16 {
  padding: 16px;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.link {
  font-size: $fs-xsmall;
  font-weight: $fw-medium;
  color: $text-color-secondary;
  display: inline-flex;

  .icon {
    margin-right: 8px;
    color: $text-color-secondary;
  }
}

.icon {
  display: inline-flex;
}

.error-text {
  margin-top: 8px;
  color: $text-color-error;
  font-size: $fs-small;
}

/* MATERIAL COMPONENT STYLE OVERRIDES */

.MuiBackdrop-root {
  background-color: $modal-backdrop-color !important;
}

.datepicker-wrapper,
.timepicker-wrapper {
  width: 100%;

  .MuiFormControl-root {
    margin-top: 16px;
    width: 100%;

    .MuiInputLabel-filled {
      font-family: $ff-primary;
      color: $text-color-dark-2;
      font-size: $fs-small;
      font-weight: $fw-bold;
      transform: none;
      position: static;
      margin-bottom: 8px;
    }

    .MuiInputBase-root.MuiFilledInput-root {
      background-color: $white;
      box-shadow: $box-shadow-basic;
      color: $text-color-dark-2;
      border-radius: $radius-primary;
      background-color: transparent;
      height: 40px;
      font-size: $fs-small;
      font-weight: $fw-medium;
      font-family: $ff-primary;
      padding: 0;

      .MuiInputBase-input {
        padding: 12px 0 12px 12px;

        &::placeholder {
          color: $text-color-grey;
          opacity: 1 !important;
          display: visible;
        }
      }

      .MuiIconButton-root {
        border-radius: 0;
      }

      .MuiIconButton-label {
        width: 16px;
        height: 16px;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &::before,
      &::after {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiFormHelperText-root {
      display: none;
    }

    .MuiInputAdornment-positionEnd {
      margin: 0;
    }
  }
}

.MuiPaper-elevation8 {
  box-shadow: $box-shadow-basic !important;
}

.MuiPickersBasePicker-container {
  .MuiTouchRipple-root {
    display: none;
  }

  .MuiPickersDay-daySelected {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary, 5%);
    }

    &:focus {
      background-color: darken($secondary, 10%);
    }
  }

  .MuiPickersClockPointer-thumb {
    border-color: $secondary;
  }

  .MuiToolbar-root,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-pointer,
  .MuiPickersClockPointer-thumb {
    background-color: $secondary;
  }

  .MuiTypography-body1,
  .MuiTypography-body2,
  .MuiTypography-h2,
  .MuiTypography-h4,
  .MuiTypography-subtitle1,
  .MuiTypography-caption {
    font-family: $ff-primary;
  }
}

.MuiTooltip-popper {
  left: 25% !important;

  .MuiTooltip-tooltip {
    font-size: $fs-xsmall;
    font-family: $ff-primary;
    color: $text-color-dark-2;
    background-color: $text-color-grey-2;
    padding: 12px;
  }
}

.chrome-picker {
  padding: 40px 14px 53px 21px;
  margin-left: 20px;
  height: 130px;
  font-family: $ff-primary !important;

  .flexbox-fix {
    display: none !important;
  }
}

.border-ghost {
  border: 4px solid #4da8ff;
  border-radius: 4px;
}

.border-ghost .ghost-title {
  display: none;
}

.component-drop-preview {
  margin-top: 10px;
  border-top: 4px solid #4da8ff;
}

.component-drop-preview:before,
.component-drop-preview:after {
  content: "";
  margin-top: 10px;
  width: 15px;
  height: 15px;
  background: #4da8ff;
  border-radius: 7.5px;
  position: absolute;
  top: -5px;
}

.component-drop-preview:after {
  right: 0;
}

.Toastify__toast-body {
  font-family: "Red Hat Display";
  font-size: 15px;
}

.smooth-dnd-container {
  min-height: 5px !important;
}

.card-ghost {
  // border: 1px solid blue !important;
}

// .smooth-dnd-ghost {
//   height: 50px !important;
//   .smooth-dnd-container {
//     opacity: 0 !important;
//     display: none !important;
//   }
// }

.drop-preview {
  // height: 50px !important;
  position: relative !important;
  border: 2px solid #1f81df !important;
  border-radius: 4px;
  background-color: rgb(248, 248, 248);
  margin-bottom: 10px;
}

.accordion_category {
  position: absolute;
  left: -20px;
  top: 17px;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
  margin-right: 5px;
}

.smooth-dnd-container {
  opacity: 1;
  // transition: all 0.3s ease-in-out;
  // max-height: 2500px;
}

.children_hide {
  .accordion_category {
    transform: rotate(0);
  }
  .smooth-dnd-draggable-wrapper {
    display: none !important;
  }
}

@font-face {
  font-family: "Red Hat Display";
  src: local("Red Hat Display"),
    url(./assets/fonts/RedHatDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Red Hat Display Bold";
  src: local("Red Hat Display Bold"),
    url(./assets/fonts/RedHatDisplay-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato Bold";
  src: local("Lato Bold"), url(./assets/fonts/Lato-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Slab";
  src: local("Roboto Slab"),
    url(./assets/fonts/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Slab Bold";
  src: local("Roboto Slab Bold"),
    url(./assets/fonts/RobotoSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("Open Sans Bold"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"),
    url(./assets/fonts/HelveticaNeue-Light.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: local("Helvetica Neue Bold"),
    url(./assets/fonts/HelveticaNeue-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Medium";
  src: local("Poppins Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: local("Poppins SemiBold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins Bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
