@import "../../../assets/styles/main.scss";

.BottomNav {
  z-index: 99;

  .FullBottomNavItem {
    display: flex;
    align-items: center;
    height: 41px;
    width: 100%;
    font-size: $fs-xsmall;
    color: $text-color-white;
    cursor: pointer;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    &:hover {
      background-color: #204150;
    }
  }
  .BottomNavItem {
    display: flex;
    align-items: center;
    height: 41px;
    width: 52px;
    font-size: $fs-xsmall;
    color: $text-color-white;
    cursor: pointer;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    &:hover {
      width: 52px;
      background-color: #204150;
      cursor: pointer;
    }
  }
  .expireDate {
    font-size: 14px;
    color: $text-color-white;
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 8px;
    padding-left: 15px;
  }

  .pricingBannerIcon {
    margin-right: 5px;
  }

  .pricingBannerLabel {
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: $fs-xsmall;
    font-weight: bold;
    color: #ff8360;
  }
  .planLabel{
    width: 147px;
    height: 28px;
    margin: 8px 13px 16px 0;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9fafb6;
    .planLabelDesc{
      font-weight: normal;
    }
    .planLabelDesc2{

    }
  }

  li:last-child {
    margin-bottom: 16px;
  }
}

.Label {
  margin-left: 15px;
}
.versionContainer{
  width: 50%;
}
.versionLabel {
  padding-left: 15px;
  color: #fff;
  font-size: $fs-xsmall;
  font-weight: 500;
  margin-left: 45px;
}

.logOutContextMenu {
  position: absolute;
  bottom: 10px;
  width: 120px;
}

.logoutMenuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: $radius-primary;
  z-index: $z-index-top;
  box-shadow: $box-shadow-basic;
  margin-top: 8px;
  overflow: hidden;
  li:not(:last-of-type) {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: #e7eaec;
      z-index: $z-index-top;
    }
  }
}

.logOutBtn {
  display: flex;
  padding: 8px;
  transition: $transition-basic;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  background: white;
  color: #3e5e6e !important;
  align-items: center;
  border: none;
  font-size: 14px !important;
  line-height: 1.29;
  font-weight: $fw-medium;
  text-align: left;
  width: 128px;
  height: 52px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);

  &:active {
    color: $text-color-dark-2;
  }
  i {
    margin: 0 8px;
  }
}

.helpContextMenu {
  position: absolute;
  bottom: 50px;
  width: 218px;
  background-color: white;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  border-radius: 4px;
  padding: 16px;
  .helpMenuTitle {
    font-size: 14px;
    font-weight: bold;
    color: #3e5e6e;
  }
  .contextMenuItem {
    width: 100%;
    font-size: 14px;
    color: #3e5e6e;
    display: flex;
    align-items: center;
    cursor: hand;

    .menuItemBtn {
      display: flex;
      transition: $transition-basic;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      background: white;
      color: #3e5e6e !important;
      align-items: center;
      border: none;
      font-size: 14px !important;
      line-height: 1.29;
      font-weight: $fw-medium;
      text-align: left;
      height: 30px;
      border-radius: 4px;
      cursor: pointer;
      &:active {
        color: $text-color-dark-2;
      }
      i {
        margin-right: 8px;
      }
    }
  }
}
.Line {
  width: 87%;
  height: 2px;
  border-top: solid 1px #3e5e6e;
  margin:8px 16px;


}
