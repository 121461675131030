@import "../../../assets/styles/main.scss";

.HeaderDropdown {
  position: relative;
  margin-bottom: 20px;
}

.Select {
  display: block;
  font-size: 70%;
  font-family: inherit;
  font-weight: 500;
  color: #9fafb6;
  padding: 12px 16px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1),
    0 2px 11px 0 rgba(15, 54, 73, 0);
  appearance: none;

  /*Removes border*/
  -webkit-appearance: none;

  /*Removes default chrome and safari style*/
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.menuActivator {
  border-radius: $radius-primary;
  box-shadow: $box-shadow-basic;
  padding: 4px 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #204150;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.menuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: $radius-primary;
  z-index: $z-index-top;
  box-shadow: $box-shadow-basic;
  margin-top: 8px;
  width: 300px;
  li {
    position: relative;
  }
  li:not(:last-child):after {
    content: "";
    display: block;
    height: 1px;
    background-color: #e7eaec;
    z-index: $z-index-top;
    margin: 0 16px;
  }
}

.arrowIcon {
  display: flex;

  &.rotateIcon {
    transform: rotate(180deg);
  }
}

.Small {
  padding: 10px 8px;
  font-size: 55%;
}

.DropdownInput {
  color: #fff;
  background-color: #204150;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder {
    color: #9fafb6;
    font-size: 11px;
  }
}

.Light {
  color: #9fafb6;
  background-color: #ffffff;
}

.Invalid {
  border: $border-error;
}

.Empty {
  color: #9fafb6;
  font-size: 11px;
}

.menuActivator,
.appMenu {
  width: 100%;
  border: none;
  font-size: $fs-small;
  font-weight: $fw-medium;
  text-align: left;
  cursor: pointer;
  &:active {
    color: $text-color-dark-2;
  }
}

.appMenu {
  transition: $transition-basic;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  background: white;
  color: #3e5e6e;
  display: flex;
  border-radius: 4px;
  flex-direction: column;

  .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 16px 0 16px;
    border-radius: 4px;

    .optionGroup {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      .appName {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e5e6e;
        &:hover {
          color: #3196f6;
        }
      }
      .activeApp {
        color: #3196f6;
      }
      .createdDate {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e5e6e;
        padding-bottom: 12px;
      }
    }
    .optionMenu {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    .optionImg {
      display: none;
    }
  }

  .createNewApp {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;

    .addIcon {
      display: flex;
      align-items: center;
    }
    .text {
      display: flex;
      align-items: center;
      color: #3e5e6e;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-left: 8px;
      &:hover {
        color: #3196f6;
      }
    }
  }

  li {
    &:hover {
      background-color: #f3f5f6;
      border-radius: 4px;
    }
  }
}

.contextMenu {
  background-color: #fff;
  width: 110px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  text-align: center;
  z-index: 1;
  &:after {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    left: -25px;
    border: 15px solid transparent;
    border-bottom-color: #fff;
    content: “”;
    top: 25px;
    transform: rotate(-90deg);
  }
  .contextMenuItem {
    width: 110px;
    height: 34px;
    font-size: 14px;
    color: #3e5e6e;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    font-family: “Red Hat Display”, sans-serif;
    cursor: hand;
    &:hover {
      background-color: #f3f5f6;
      border-radius: 5px;
    }
  }
}
