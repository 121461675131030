.Modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
  background-color: #fff;
  width: 819px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 24px;
  transition: all 0.3s ease-out;
}
