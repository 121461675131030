@import "../../../assets/styles/main.scss";

#upgradeComponent {
  width: 240px;
  height: 210px;
  border-radius: 4px;
  background-color: rgba($color: #ffe3db, $alpha: 1);
  margin: 16px 0;
  align-self: center;
  padding: 8px;
  overflow: hidden;

  .upgradeComponent {
    .topContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .topRow {
      display: flex;
      align-items: center;

      .icon {
        width: 22px;
        height: 28px;
      }
    }

    .leftText {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0f3649;
    }

    .rightText {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0f3649;

      span {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .desc {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e5e6e;
      margin: 5px 0 10px;
      object-fit: contain;
    }

    .btnText {
      color: #fff;
    }
  }
}

.item{
  padding: 5px;
  padding-top: 0;
}

.span{
  font-weight: 100 !important;
  margin-right: 3px !important;
  font-size: 11px !important;
}

.splashScreen {
  width: 184px;
  height: 32px;
  margin: 4px 0;
  object-fit: contain;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
}

.splashScreen .growthText {
  color: #ff8360;
}

.seePlanPrice {
  width: 184px;
  height: 14px;
  margin: 4px 0 12px;
  object-fit: contain;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1f81df;
}

.seePlanPrice .priceText {
  font-weight: normal;
  color: #3e5e6e;
}

.categoryIcon {
  width: 22px;
  height: 28px;
  margin: 5px;
  align-self: center;
}

.categoryLayout {
  margin: 4px 0;
  object-fit: contain;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  padding-right: 50px;
}
.seeCategoryLayoutPlanPrice {
  margin: 0;
  object-fit: contain;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1f81df;
  .growthText {
    color: #ff8360;
  }
  .priceText {
    font-weight: normal;
    color: #3e5e6e;
    cursor: auto;
  }
  .Category {
    .desc {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e5e6e;
      margin: 5px 0 10px;
      object-fit: contain;
      width: 100%;
    }
  }
}
.Close {
  cursor: pointer;
  position: absolute;
  right: -50px;
  top: 0;

  img {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
}

.flex {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
