@import "../../../assets/styles/main.scss";

.ViewContainer {
  padding: 2px 16px 0 16px;
  background-color: $text-color-light-1;
}

.BlockLabel {
  display: block;
  font-size: $fs-small;
  font-weight: $fw-bold;
  line-height: 150%;
  margin-bottom: 8px;
  margin-top: 16px;
  color: $text-color-dark-1;
}

.InfoText {
  width: 206px;
  height: 45px;
  font-size: 12px;
  font-weight: $fw-medium;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $text-color-dark-1;
}

.DesignConfigContainer {
  height: 100%;
  width: 272px;
  float: left;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
}

.componentsContainer {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  height: 100%;
}

.MobileScreen {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 25px;
  min-height: 700px;
}

.ButtonContainer {
  bottom: 0;
  right: 0;
}

.EmptyNav {
  width: 72px;
  height: 72px;
  margin: 4px;
}

.Nav {
  margin: 8px 0px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin: 4px;
    border: solid 1px #e7eaec;
  }
}

.ActiveBorder {
  border: solid 1px #3e5e6e !important;
}

.Label {
  width: 43px;
  height: 16px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9fafb6;
}

.Active {
  color: #3e5e6e;
}

// Drop container
.dropContainer {
  position: relative;
  width: 100%;
  height: 360px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: scroll;
  border-radius: 10px;
}

.dropContainer::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.dropContainer::-webkit-scrollbar {
  width: 0px;
}
.dropArea {
  margin-bottom: 120px;
  height: 100px;
  text-align: center;
  overflow: hidden;
}
.contextMenu {
  background-color: #fff;
  width: 184px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  text-align: center;
  padding: 8px 16px 8px 25px;
  left: 675px !important;
  z-index: 999999999999;
  margin-top: -30px;
  &:after {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    left: -25px;
    border: 15px solid transparent;
    border-bottom-color: #fff;
    content: “”;
    top: 25px;
    transform: rotate(-90deg);
  }
  .contextMenuItem {
    width: 144px;
    height: 50px;
    font-size: 14px;
    color: #3e5e6e;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    font-family: “Red Hat Display”, sans-serif;
    cursor: hand;
    &:hover {
      background-color: #f3f5f6;
      border-radius: 5px;
    }
  }
}

.ErrorField {
  border-radius: 8px;
  background-color: rgba(230, 101, 101, 0.15);
  padding: 8px 16px;
  margin-top: 16px;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: #e66565;
  }
}

.ModalTitle {
  margin-top: 16px;
  .ModalTitleText {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 38px;
  text-align: center;
  line-height: 1;

  .ModalBodyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }
}

.ModalFooter {
  margin-top: 20px;
  .ButtonContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    > button:first-child {
      margin-right: 20px;
    }
  }
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.placeholder {
  display: none;
}
.menuNavigation {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column: 3;
  justify-content: center;
  padding: 20px 8px 10px 8px;
  width: 240px;
  z-index: 99;
  .menuLeft {
    display: flex;
    .hamburgerMenu {
      width: 16px;
      height: 14px;
      object-fit: contain;
    }

    .back {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }
  }

  .menuCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .appBrandLogo {
      display: flex;
      justify-content: center;
      width: 100px;
      height: 16px;
      object-fit: contain;
    }

    .productListTitle {
      width: 120px;
      height: 18px;
      font-size: 10.5px;
      font-weight: $fw-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0f3649;
    }

    .productListItemCount {
      display: flex;
      justify-content: center;
      width: 120px;
      height: 15px;
      font-size: 9px;
      font-weight: $fw-medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0f3649;
    }
  }

  .menuRight {
    display: flex;
    justify-content: flex-end;

    .search {
      width: 13px;
      height: 14px;
      object-fit: contain;
      margin: 4px;
    }

    .basket {
      width: 15px;
      height: 14px;
      object-fit: contain;
      margin: 4px;
    }

    .share {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }

  .menuRightMargin {
    margin-top: 4px;
  }

  .appWelcomeText {
    font-size: $fs-xsmall;

    span {
      display: block;
      font-size: $fs-p;
      font-weight: $fw-bold;
    }
  }
}

.tabContainer {
  .tabGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 216px;
    height: 24px;
    padding: 16px;
    margin: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
    background-color: white;

    button:not(:last-child) {
      border-right: 1px solid #e7eaec;
      border-radius: 2px;
    }

    .sort {
      border-radius: $radius-primary;
      width: 50%;
      border-width: 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      font-weight: $fw-bold;
      align-items: center;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0f3649;
      i {
        margin: 8px;
      }
    }

    .filter {
      border-radius: $radius-primary;
      width: 50%;
      font-size: 12px;
      display: flex;
      border-width: 0;
      justify-content: center;
      align-items: center;
      background-color: white;
      font-weight: $fw-bold;
      align-items: center;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0f3649;
      i {
        margin: 8px;
      }
    }
  }
}

.centerBar {
  display: flex;
  justify-content: center;
  width: 130px;
  height: 30px;
  border-radius: 68px;
  background-color: #e7eaec;
  position: absolute;
  top: -3%;
  left: 25%;
  z-index: 99;
}

.slider {
  margin-top: 12px;
  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: contain;
  }
}
