@import "../../../../assets/styles/main.scss";

.EngageSubRouteContainer {
  height:100%;
  width: 100%;
  padding: 32px;
  overflow: auto;
}

.Heading {
  font-size: 20px;
  font-weight: 900;
  color: #3e5e6e;
}
.Info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #3e5e6e;
  margin-top: 16px;
}

.LanguageContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  .LanguageItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    .BlockLabel {
      display: block;
      font-size: 14px;
      font-weight: $fw-bold;
      line-height: 150%;
      color: #3e5e6e;
      margin-bottom: 8px;
    }
  }

  .LanguageItem:first-child {
    margin-right: 16px;
  }
}

.contentContainer {
  height: 100%;
  width: 100%;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 12px 0;
  border-top: 1px solid #e7eaec;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.ModalTitle {
  .ModalTitleText {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e5e6e;
    text-align: left;
  }
}
.ModalBodyConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBody {
  margin-top: 32px;
  width: 400px;
  .ModalBodyText {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #3e5e6e;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: #3e5e6e;
    span.blueText {
      color: #4da8ff;
      font-weight: 500;
    }
  }
}

.ModalForm {
  margin-top: 20px;
}

.ModalFooter {
  width: 98%;
  .ButtonContainer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
.searchContainer {
  background-color: #fff;
  padding:4px 16px 16px 16px;
  margin-top: 8px;
  justify-content: flex-start;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  .search {
    position: relative;
    display: inline-block;
    width: 100%;
    .searchIcon{
      position: absolute;
      right: 0;
      top: 8px;
      background: transparent;
      padding: 4px;
      margin: 4px;
    }
  }
}

.menuBtn {
  transition: $transition-basic;
  background-color: white;
  border: 0;
  color: #3e5e6e;
  font-family: inherit;
  font-size: 14px;
  font-weight: $fw-normal;
}

.GoBack {

}

.Container {
  padding: 12px;
  text-align: center;
}
.ImageWrapper {
  display: flex;
  justify-content: flex-start;
}
.Logo {
  width: 110px;
  height: 22px;
}
.IconWrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.Close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px 18px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
.Icon {
  width: 84px;
  height: 70px;
}

.Description {
  margin-top: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #0f3649;
}
.ConfirmIcon {
  margin-top: 30px;
}
