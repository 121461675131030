.alert {
  width: 380px;
  position: absolute;
  right: 42px;
  top: 24px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: rgba($color: #ff8360, $alpha: 0.18);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alertTitleButton {
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #ff8360;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  width: 84px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertIcon {
  width: 20px;
  height: 20px;
}

.closeIcon {
  width: 12px;
  height: 12px;
}

.alertClose {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
}

.alertBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}

.alertDescription {
  color: #0f3649;
  font-weight: 500;
  font-size: 12px;
  margin-right: 15px;
}

.pricingLink {
  font-weight: bold;
  color: #1f81df;
}
