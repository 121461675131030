// FONT STYLES
$fw-thin: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-bolder: 900;

$fs-xsmall: 12px;
$fs-small: 16px;
$fs-p: 16px;
$fs-regular: 20px;
$fs-title: 24px;
$fs-h2: 28px;

// ALPHA COLORS
$shadow-color-light: rgba(22, 33, 43, 0.12);
$modal-backdrop-color: rgba($primary, 0.65);

// Z-INDEX
$z-index-normal: 0;
$z-index-top: 5;
$z-index-toaster: 9;
$z-index-backdrop: 99;
$z-index-modal: 999;

// SHADOWS
$box-shadow-basic: 0 2px 15px 0 $shadow-color-light;
$box-shadow-hover: 0 5px 12px 0 $shadow-color-light;
$box-shadow-black: 1px 1px 1px $black;
$box-shadow-spinner: inset 0 0 0 1em $white;
$box-shadow-tbody: 0 0 0 1px $theme-grey-2;
$box-shadow-tcell: 0 0 0 0.5px $theme-grey-2;
$box-shadow-tcell-active: 0 0 0 2px $grey;

// BORDER RADIUS
$radius-primary: 4px;
$radius-btn: 5px;
$radius-card: 8px;

// BORDERS
$border-table: 1px solid $theme-grey-2;
$border-divider: 2px solid $theme-grey-2;
$border-light: 1px solid $light;
$border-primary: 2px solid $primary;
$border-secondary: 2px solid $secondary;
$border-transparent: 2px solid transparent;
$border-dark: 2px solid $dark;
$border-disabled: 2px solid $disabled;
$border-error: 2px solid $error;
$border-active: 2px solid $primary;

$border-input-default: 2px solid transparent;

// TRANSITION AND TRANSFORMS
$transition-basic: all 200ms ease-in-out;

// Screen Sizes:
$screen-xl: 1440px;
