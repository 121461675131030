.root {
  padding-inline-start: 8px;
  height: 40px;
  padding: 11px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #fff;
  width: 410px;
}

.label {
  width: 100%;
  display: inline-block;
  font-family: "Red Hat Display";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
