@import "../../../assets/styles/main.scss";

.spinnerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(12, 61, 83, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-modal;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.shown {
  opacity: 1;
  visibility: visible;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  border-radius: 64px;
  background-color: #fff;
  overflow: hidden;
}

.smallImage {
  position: absolute;
  top: 0;
  background: rgb(255 255 255 / 55%);
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.gifContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinnerText {
  color: #fff;
  font-size: 14px;
  font-family: "Red Hat Display Bold";
  margin-top: 16px;
}
