.root {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
    0 0 0 1px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 400px;
  padding: 8px;
}

.item {
  width: 100%;
  padding-inline-start: 8px;
  height: 40px;
  padding: 11px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.icon,
.label {
  font-family: "Red Hat Display";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5e6e;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  border: solid 2px #fff;
}
