@import "../../assets/styles/main.scss";

.Container {
  background-color: #3196f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.Heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 100px;
  font-size: 76px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 6px;
}

.Description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 548px;
  height: 46px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 40px;
}

.GoBack {
  width: 192px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: $fw-bold;
  border-radius: $radius-btn;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
  letter-spacing: 0.5px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  &:hover {
    background-color: #cfd7db73;
    border: 2px solid #cfd7db;
  }
}
