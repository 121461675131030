@import "../../../assets/styles/main.scss";

.SideMenu {
  padding: 10px 0;
  background-color: #0f3649;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topMenu {
  padding: 0 10px 0 16px;
}

.Full {
  width: 202px;
  min-width: 202px;
}

.Narrow {
  width: 59px;
}

.ToggleIcon {
  display: block;
  height: 10px;
  cursor: pointer;
}

.MyApplicationsText {
  font-size: 50%;
  font-weight: 500;
  color: #9fafb6;
  margin-bottom: 10px;
}

.hide {
  display: none;
}
