@import "../../../../assets/styles/main.scss";

.wrapper {
  width: 100%;
  height: 56px;
  position: relative;
}

.editable {
  background-color: #f7f9f9;
}

.editBtn {
  color: $theme-dark;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input {
  top: 0;
  left: 0;
  border: 2px solid transparent;
  transition: 200ms ease-in-out;
  position: absolute;
  font-size: $fs-xsmall;
  font-weight: $fw-medium;
  padding: 20px 24px;
  width: 100%;
  height: 56px;
  z-index: $z-index-top;
  resize: none;
  box-shadow: $box-shadow-basic;
  border-radius: $radius-primary;
  font-family: $ff-primary;
  &:focus {
    border: $border-active;
  }
}

.updated {
  .editBtn {
    color: $text-color-success;
  }
}
