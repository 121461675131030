.ql-editor {
  min-height: 200px;
  color:#000;
}

.ql-toolbar.ql-snow,
.ql-toolbar.ql-snow + .ql-container.ql-snow,
.ql-editor {
  font-family: inherit !important;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 20px;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  width: 15px;
  height: 15px;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before,
.ql-snow .ql-editor h2 {
  font-size: 14px;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before,
.ql-snow .ql-editor h1 {
  font-size: 17px;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 11px;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
  color: inherit;
}
