@import "../../../assets/styles/main.scss";

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: $fw-bold;
  border: $border-transparent;
  border-radius: $radius-btn;
  border-radius: $radius-btn;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
  letter-spacing: 0.5px;

  &:disabled {
    background-color: #cfd7db;
    border: 2px solid #cfd7db;
    cursor: not-allowed;

    &:hover {
      background-color: #cfd7db;
      border: 2px solid #cfd7db;
      cursor: not-allowed;
    }
  }
}

.Primary {
  background-color: #3196f6;
  color: #fff;
  border: 2px solid #3196f6;
  &:hover {
    background-color: #1f81df;
    border: 2px solid #1f81df;
    cursor: pointer;
  }
}

.Ghost {
  background-color: #fff;
  color: #3196f6;
  border: 2px solid #3196f6;
  &:hover {
    background-color: rgba(49, 150, 246, 0.1);
    border: 2px solid #3196f6;
    cursor: pointer;
  }
}

.Primary-disabled {
  background-color: #cfd7db;
  color: #fff;
  border: 2px solid #cfd7db;
  &:hover {
    background-color: rgba(207, 215, 219, 0.8);
    border: 2px solid #cfd7db;
    cursor: pointer;
  }
}

.Auth {
  background-color: #211065;
  color: #fff;
  font-family: Poppins SemiBold;
  font-weight: inherit;
  border-radius: 24px;
}

.loader {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.loader:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff #fff #fff transparent;
  animation: loader 1.2s linear infinite;
  &:hover {
    background-color: rgba(49, 150, 246, 0.1);
    border: 2px solid #3196f6;
    cursor: pointer;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
